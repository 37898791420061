import i18next from 'i18next';
import i18n, { DEFAULT_LANGUAGE, LanguageEnum, LANGUAGES } from '../i18n';

type ReturnType = {
	language: LanguageEnum;
	changeLanguage: (language: LanguageEnum) => void;
};

const useLanguage = (): ReturnType => {
	const browserLanguage = i18n.language.substring(0, 2).toLowerCase() as LanguageEnum;
	const language = LANGUAGES.includes(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE;

	const changeLanguage = (language: LanguageEnum): void => {
		i18next.changeLanguage(language);
	};

	return { language, changeLanguage };
};

export default useLanguage;
