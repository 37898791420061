import cx from 'classnames';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useScroll } from '../../provider/ScrollProvider/ScrollProvider';
import cards from '../../styles/cards.module.scss';
import Icon from '../Icon/Icon';
import styles from './StrategyCard.module.scss';

import strategyDE from '../../assets/images/strategy_de.png';
import strategyFR from '../../assets/images/strategy_fr.png';
import strategyIT from '../../assets/images/strategy_it.png';
import useLanguage from '../../hooks/useLanguage';
import { LanguageEnum } from '../../i18n';

const StrategyCard = (): ReactElement => {
	const { t } = useTranslation();
	const { setMemorizedScrollPosition } = useScroll();

	const [isHovering, setIsHovering] = useState<boolean>(false);

	const navigate = useNavigate();

	const { language: activeLanguage } = useLanguage();

	let strategy;
	switch (activeLanguage) {
		case LanguageEnum.DE:
			strategy = strategyDE;
			break;
		case LanguageEnum.FR:
			strategy = strategyFR;
			break;
		case LanguageEnum.IT:
			strategy = strategyIT;
			break;
		default:
			strategy = strategyDE;
	}

	return (
		<div
			className={styles.container}
			onClick={() => {
				setMemorizedScrollPosition({ x: window.scrollX, y: window.scrollY });
				navigate('/strategy');
				window.scrollTo(0, 0);
			}}
			onPointerEnter={() => setIsHovering(true)}
			onPointerLeave={() => setIsHovering(false)}
		>
			<div className={styles.content}>
				<div className={cx(styles.title, cards.title)}>{t('pages.home.cards.strategy.title')}</div>
				<div className={styles.cubeWrapper}>
					<Icon type="plus" size={20} color="dark" />
					<img src={strategy} alt="cube" className={styles.cube} />
				</div>
			</div>
			<div className={cx(styles.image, isHovering && styles.imageHover)}></div>
		</div>
	);
};

export default StrategyCard;
