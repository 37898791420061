import { StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import { ScrollProvider } from './provider/ScrollProvider/ScrollProvider';
import { WindowProvider } from './provider/WindowProvider/WindowProvider';
import './styles/index.css';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<StrictMode>
		<Suspense>
			<WindowProvider>
				<ScrollProvider>
					<BrowserRouter>
						<App />
					</BrowserRouter>
				</ScrollProvider>
			</WindowProvider>
		</Suspense>
	</StrictMode>,
);
