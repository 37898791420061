import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './NotFoundPage.module.scss';

const NotFoundPage = (): ReactElement => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<h1 className={styles.title}>{t('exceptions.notfound.title')}</h1>
				<p className={styles.description}>{t('exceptions.notfound.description')}</p>
				<p onClick={() => navigate('/')} className={styles.link}>
					{t('exceptions.notfound.link')}
				</p>
			</div>
		</div>
	);
};

export default NotFoundPage;
