import cx from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import mission from '../../assets/images/mission.svg';
import { config } from '../../common/config';
import Icon from '../../components/Icon/Icon';
import { useScroll } from '../../provider/ScrollProvider/ScrollProvider';
import { useWindow } from '../../provider/WindowProvider/WindowProvider';
import pages from '../../styles/pages.module.scss';
import styles from './FedpolPage.module.scss';

const FedpolPage = (): ReactElement => {
	const { t } = useTranslation();
	const { windowWidth } = useWindow();
	const { memorizedScrollPosition } = useScroll();

	const navigate = useNavigate();

	return (
		<div className={cx(pages.wrapper, styles.wrapper)}>
			<div className={pages.container}>
				<div className={pages.closeContainer}>
					<Icon
						type="close"
						onClick={() => {
							navigate('/');
							window.scrollTo(memorizedScrollPosition.x, memorizedScrollPosition.y);
						}}
					/>
				</div>
				<div className={pages.contentWrapper}>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-left" size={48} onClick={() => navigate('/values')} />
					)}
					<div className={pages.contentContainer}>
						<div className={styles.fedpolContainer}>
							<img src={mission} alt="mission" className={styles.mission} />
						</div>
						<div className={pages.textContainer}>
							{windowWidth > config.viewSwitchWidth && (
								<p className={pages.header}>{t('pages.fedpol.text.header')}</p>
							)}
							<p className={pages.title}>{t('pages.fedpol.text.title')}</p>
							<p
								className={pages.description}
								dangerouslySetInnerHTML={{ __html: t('pages.fedpol.text.description') }}
							>
								{}
							</p>
						</div>
					</div>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-right" size={48} onClick={() => navigate('/strategy')} />
					)}
				</div>
			</div>
		</div>
	);
};

export default FedpolPage;
