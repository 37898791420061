import { ReactElement } from 'react';
import fedpolLogoFr from '../../assets/images/fedpol-logo-fr.svg';
import fedpolLogoIt from '../../assets/images/fedpol-logo-it.svg';
import fedpolLogoDe from '../../assets/images/fedpol-logo.svg';
import useLanguage from '../../hooks/useLanguage';
import { LanguageEnum } from '../../i18n';
import styles from './Logo.module.scss';

const Logo = (): ReactElement => {
	const { language } = useLanguage();

	let translatedLogo;
	switch (language) {
		case LanguageEnum.DE:
			translatedLogo = fedpolLogoDe;
			break;
		case LanguageEnum.FR:
			translatedLogo = fedpolLogoFr;
			break;
		case LanguageEnum.IT:
			translatedLogo = fedpolLogoIt;
			break;
		default:
			translatedLogo = fedpolLogoDe;
	}

	return <img src={translatedLogo} alt="fedpol logo" className={styles.logo} />;
};

export default Logo;
