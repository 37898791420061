import { debounce } from 'lodash';
import { createContext, ReactElement, ReactNode, useContext, useEffect, useState } from 'react';

const WindowContext = createContext<Values>({
	windowWidth: 0,
});

type Props = {
	children: ReactNode;
};

type Values = {
	windowWidth: number;
};

const useWindow = (): Values => {
	return useContext(WindowContext);
};

const WindowProvider = ({ children }: Props): ReactElement => {
	const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

	const handleResize = debounce((): void => {
		setWindowWidth(window.innerWidth);
	}, 10);

	useEffect(() => {
		window.addEventListener('resize', handleResize, false);
		return () => window.removeEventListener('resize', handleResize, false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const value: Values = { windowWidth };

	return <WindowContext.Provider value={value}>{children}</WindowContext.Provider>;
};

export { WindowProvider, useWindow };
