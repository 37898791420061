import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export enum LanguageEnum {
	DE = 'de',
	FR = 'fr',
	IT = 'it',
}
export type LanguageIdentifier = LanguageEnum.DE | LanguageEnum.FR | LanguageEnum.IT;

export const LANGUAGES = [LanguageEnum.DE, LanguageEnum.FR, LanguageEnum.IT];
export const DEFAULT_LANGUAGE = LanguageEnum.DE;

i18n
	.use(HttpBackend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: LanguageEnum.DE,
		debug: false,
		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
