import cx from 'classnames';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiCycling } from 'react-icons/bi';
import { FaWalking } from 'react-icons/fa';
import { FiCheckSquare } from 'react-icons/fi';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import sitePlanDE from '../../assets/images/site-plan-de.png';
import sitePlanFR from '../../assets/images/site-plan-fr.svg';
import sitePlanIT from '../../assets/images/site-plan-it.svg';
import waypointHouse from '../../assets/images/waypoint-house.svg';
import bicycle from '../../assets/videos/bicycle.mp4';
import byFoot from '../../assets/videos/byFoot.mp4';
import firstDay from '../../assets/videos/firstDay.mp4';

import { config } from '../../common/config';
import Icon from '../../components/Icon/Icon';
import useLanguage from '../../hooks/useLanguage';
import { LanguageEnum } from '../../i18n';
import { useScroll } from '../../provider/ScrollProvider/ScrollProvider';
import { useWindow } from '../../provider/WindowProvider/WindowProvider';
import pages from '../../styles/pages.module.scss';
import styles from './WaypointPage.module.scss';

type Tab = {
	tab: 'first-day' | 'by-foot' | 'by-bicycle' | 'by-car' | 'site-plan';
	icon: ReactElement;
	label: string;
	video?: string;
	image?: string;
};

const WaypointPage = (): ReactElement => {
	const { t } = useTranslation();
	const { windowWidth } = useWindow();
	const { memorizedScrollPosition } = useScroll();

	const { language } = useLanguage();

	let sitePlan;
	switch (language) {
		case LanguageEnum.DE:
			sitePlan = sitePlanDE;
			break;
		case LanguageEnum.FR:
			sitePlan = sitePlanFR;
			break;
		case LanguageEnum.IT:
			sitePlan = sitePlanIT;
			break;
		default:
			sitePlan = sitePlanDE;
	}

	const desktopTabs: Tab[] = [
		{
			tab: 'first-day',
			icon: <FiCheckSquare />,
			label: t('pages.waypoint.tabs.first-day'),
			video: firstDay,
		},
		{
			tab: 'by-foot',
			icon: <FaWalking />,
			label: t('pages.waypoint.tabs.by-foot'),
			video: byFoot,
		},
		{
			tab: 'by-bicycle',
			icon: <BiCycling />,
			label: t('pages.waypoint.tabs.by-bicycle'),
			video: bicycle,
		},
		{
			tab: 'site-plan',
			icon: <HiOutlineLocationMarker />,
			label: t('pages.waypoint.tabs.site-plan'),
			image: sitePlan,
		},
	];

	const mobileTabs: Tab[] = [
		{
			tab: 'first-day',
			icon: <FiCheckSquare />,
			label: t('pages.waypoint.tabs.first-day'),
			video: firstDay,
		},
		{
			tab: 'by-foot',
			icon: <FaWalking />,
			label: t('pages.waypoint.tabs.by-foot'),
			video: byFoot,
		},
		{
			tab: 'by-bicycle',
			icon: <BiCycling />,
			label: t('pages.waypoint.tabs.by-bicycle'),
			video: bicycle,
		},
	];

	const [activeTab, setActiveTab] = useState<Tab>(
		windowWidth > config.viewSwitchWidth ? desktopTabs[0] : mobileTabs[0],
	);

	const navigate = useNavigate();

	return (
		<div className={cx(pages.wrapper, styles.wrapper)}>
			<div className={pages.container}>
				<div className={pages.closeContainer}>
					<Icon
						type="close"
						color="dark"
						onClick={() => {
							navigate('/');
							window.scrollTo(memorizedScrollPosition.x, memorizedScrollPosition.y);
						}}
					/>
				</div>
				<div className={pages.contentWrapper}>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-left" size={48} onClick={() => navigate('/figures')} />
					)}
					<div className={pages.contentContainer}>
						{windowWidth > config.viewSwitchWidth && (
							<div className={styles.waypointContainer}>
								<div className={styles.waypointTabs}>
									{desktopTabs.map((tab, index) => (
										<div
											key={index}
											className={cx(styles.tab, tab.tab === activeTab.tab && styles.activeTab)}
											onClick={() => setActiveTab(tab)}
										>
											{tab.icon}
											<p>{tab.label}</p>
										</div>
									))}
								</div>
								{desktopTabs.map((tab, index) => (
									<div key={index}>
										{activeTab.tab === tab.tab && tab.image && (
											<img src={tab.image} alt={tab.tab} className={styles.waypointImage} />
										)}
										{activeTab.tab === tab.tab && tab.video && (
											// eslint-disable-next-line jsx-a11y/media-has-caption
											<video controls src={tab.video} className={styles.waypointImage} />
										)}
									</div>
								))}
							</div>
						)}
						<div className={pages.textContainer}>
							{windowWidth <= config.viewSwitchWidth && (
								<img src={waypointHouse} alt="waypoint-house" className={styles.houseImage} />
							)}
							{windowWidth > config.viewSwitchWidth && (
								<p className={pages.header}>{t('pages.waypoint.text.header')}</p>
							)}
							<p className={pages.title}>{t('pages.waypoint.text.title')}</p>
							<p
								className={cx(pages.description, styles.padding)}
								dangerouslySetInnerHTML={{ __html: t('pages.waypoint.text.description1') }}
							></p>
							<p className={pages.description}>{t('pages.waypoint.text.description2')}</p>
						</div>
						{windowWidth <= config.viewSwitchWidth && (
							<div className={styles.waypointContainer}>
								<div className={styles.waypointTabs}>
									{mobileTabs.map((tab, index) => (
										<div
											key={index}
											className={cx(styles.tab, tab.tab === activeTab.tab && styles.activeTab)}
											onClick={() => setActiveTab(tab)}
										>
											{tab.icon}
											<p>{tab.label}</p>
										</div>
									))}
								</div>
								{mobileTabs.map((tab, index) => (
									<div key={index}>
										{activeTab.tab === tab.tab && tab.image && (
											<img src={tab.image} alt={tab.tab} className={styles.waypointImage} />
										)}
										{activeTab.tab === tab.tab && tab.video && (
											// eslint-disable-next-line jsx-a11y/media-has-caption
											<video controls src={tab.video} className={styles.waypointImage} />
										)}
									</div>
								))}
								<img
									src={sitePlan}
									alt="site-plan"
									className={cx(styles.waypointImage, styles.padding)}
								/>
							</div>
						)}
					</div>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-right" size={48} invisible onClick={() => navigate('/fedpol')} />
					)}
				</div>
			</div>
		</div>
	);
};

export default WaypointPage;
