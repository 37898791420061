import cx from 'classnames';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useScroll } from '../../provider/ScrollProvider/ScrollProvider';
import cards from '../../styles/cards.module.scss';
import Icon from '../Icon/Icon';
import styles from './FiguresCard.module.scss';

const FiguresCard = (): ReactElement => {
	const { t } = useTranslation();
	const { setMemorizedScrollPosition } = useScroll();

	const [isHovering, setIsHovering] = useState<boolean>(false);

	const navigate = useNavigate();

	return (
		<div
			className={styles.container}
			onClick={() => {
				setMemorizedScrollPosition({ x: window.scrollX, y: window.scrollY });
				navigate('/figures');
				window.scrollTo(0, 0);
			}}
			onPointerEnter={() => setIsHovering(true)}
			onPointerLeave={() => setIsHovering(false)}
		>
			<div className={styles.content}>
				<div className={cx(styles.title, cards.title)}>{t('pages.home.cards.figures.title')}</div>
				<Icon type="plus" size={20} />
			</div>
			<div className={cx(styles.image, isHovering && styles.imageHover)}></div>
		</div>
	);
};

export default FiguresCard;
