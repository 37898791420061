import { ReactElement, useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header/Header';
import FedpolPage from './container/FedpolPage/FedpolPage';
import FiguresPage from './container/FiguresPage/FiguresPage';
import HomePage from './container/HomePage/HomePage';
import NotFoundPage from './container/NotFoundPage/NotFoundPage';
import StrategyPage from './container/StrategyPage/StrategyPage';
import ValuesPage from './container/ValuesPage/ValuesPage';
import WaypointPage from './container/WaypointPage/WaypointPage';

const App = (): ReactElement => {
	const [hasHeader, setHasHeader] = useState<boolean>(false);

	const location = useLocation();

	const headerRoutes: string[] = ['/', '/values', '/fedpol', '/strategy', '/figures', '/waypoint'];

	useLayoutEffect(() => {
		headerRoutes.includes(location.pathname) ? setHasHeader(true) : setHasHeader(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<div className="main-wrapper">
			{hasHeader && <Header />}
			<Routes>
				<Route path="/" element={<HomePage />} />
				<Route path="/values" element={<ValuesPage />} />
				<Route path="/fedpol" element={<FedpolPage />} />
				<Route path="/strategy" element={<StrategyPage />} />
				<Route path="/figures" element={<FiguresPage />} />
				<Route path="/waypoint" element={<WaypointPage />} />

				<Route path="*" element={<NotFoundPage />} />
			</Routes>
		</div>
	);
};

export default App;
