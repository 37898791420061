import { ReactElement } from 'react';
import useLanguage from '../../hooks/useLanguage';
import styles from './LanguageSpan.module.scss';
import { LANGUAGES } from '../../i18n';
import cx from 'classnames';

const LanguageSpan = (): ReactElement => {
	const { language, changeLanguage } = useLanguage();

	return (
		<div className={styles.span}>
			<div className={styles.languages}>
				{LANGUAGES.map((lang, index) => (
					<div
						key={index}
						className={cx(styles.language, lang === language && styles.activeLanguage)}
						onClick={() => changeLanguage(lang)}
					>
						{lang
							.split('')
							.map((l, i) => (i === 0 ? l.toUpperCase() : l))
							.join('')}
					</div>
				))}
			</div>
		</div>
	);
};

export default LanguageSpan;
