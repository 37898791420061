import {
	createContext,
	Dispatch,
	ReactElement,
	ReactNode,
	SetStateAction,
	useContext,
	useState,
} from 'react';

const ScrollContext = createContext<Values>({
	memorizedScrollPosition: { x: 0, y: 0 },
	setMemorizedScrollPosition: () => null,
});

type Position = {
	x: number;
	y: number;
};

type Props = {
	children: ReactNode;
};

type Values = {
	memorizedScrollPosition: Position;
	setMemorizedScrollPosition: Dispatch<SetStateAction<Position>>;
};

const useScroll = (): Values => {
	return useContext(ScrollContext);
};

const ScrollProvider = ({ children }: Props): ReactElement => {
	const [memorizedScrollPosition, setMemorizedScrollPosition] = useState<Position>({
		x: 0,
		y: 0,
	});

	const value: Values = { memorizedScrollPosition, setMemorizedScrollPosition };

	return <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>;
};

export { ScrollProvider, useScroll };
