import cx from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { config } from '../../common/config';
import Icon from '../../components/Icon/Icon';
import { useScroll } from '../../provider/ScrollProvider/ScrollProvider';
import { useWindow } from '../../provider/WindowProvider/WindowProvider';
import pages from '../../styles/pages.module.scss';
import styles from './StrategyPage.module.scss';

import strategyDE from '../../assets/images/strategy_de.png';
import strategyFR from '../../assets/images/strategy_fr.png';
import strategyIT from '../../assets/images/strategy_it.png';
import useLanguage from '../../hooks/useLanguage';
import { LanguageEnum } from '../../i18n';

const StrategyPage = (): ReactElement => {
	const { t } = useTranslation();
	const { windowWidth } = useWindow();
	const { memorizedScrollPosition } = useScroll();

	const navigate = useNavigate();

	const { language: activeLanguage } = useLanguage();

	let strategy;
	switch (activeLanguage) {
		case LanguageEnum.DE:
			strategy = strategyDE;
			break;
		case LanguageEnum.FR:
			strategy = strategyFR;
			break;
		case LanguageEnum.IT:
			strategy = strategyIT;
			break;
		default:
			strategy = strategyDE;
	}

	return (
		<div className={cx(pages.wrapper, styles.wrapper)}>
			<div className={pages.container}>
				<div className={pages.closeContainer}>
					<Icon
						type="close"
						color="dark"
						onClick={() => {
							navigate('/');
							window.scrollTo(memorizedScrollPosition.x, memorizedScrollPosition.y);
						}}
					/>
				</div>
				<div className={pages.contentWrapper}>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-left" size={48} color="dark" onClick={() => navigate('/fedpol')} />
					)}
					<div className={pages.contentContainer}>
						<div className={styles.strategyContainer}>
							<img src={strategy} alt="cube" className={styles.strategy} />
						</div>
						<div className={pages.textContainer}>
							{windowWidth > config.viewSwitchWidth && (
								<p className={pages.header}>{t('pages.strategy.text.header')}</p>
							)}
							<p className={pages.title}>{t('pages.strategy.text.title')}</p>
							<p
								className={pages.description}
								dangerouslySetInnerHTML={{ __html: t('pages.strategy.text.description') }}
							></p>
						</div>
					</div>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-right" size={48} color="dark" onClick={() => navigate('/figures')} />
					)}
				</div>
			</div>
		</div>
	);
};

export default StrategyPage;
