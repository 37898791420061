import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { config } from '../../common/config';
import FedpolCard from '../../components/FedpolCard/FedpolCard';
import FiguresCard from '../../components/FiguresCard/FiguresCard';
import Icon from '../../components/Icon/Icon';
import LanguageSpan from '../../components/LanguageSpan/LanguageSpan';
import Logo from '../../components/Logo/Logo';
import StrategyCard from '../../components/StrategyCard/StrategyCard';
import ValuesCard from '../../components/ValuesCard/ValuesCard';
import WaypointCard from '../../components/WaypointCard/WaypointCard';
import useLanguage from '../../hooks/useLanguage';
import { useWindow } from '../../provider/WindowProvider/WindowProvider';
import styles from './HomePage.module.scss';

const HomePage = (): ReactElement => {
	const { t } = useTranslation();
	const { windowWidth } = useWindow();

	const { language: activeLanguage } = useLanguage();

	return (
		<>
			<LanguageSpan />
			<div className={styles.wrapper}>
				<div className={styles.container}>
					<div className={styles.header}>
						{windowWidth > config.viewSwitchWidth && (
							<>
								<div className={styles.titleContainer}>
									<div className={styles.title}>
										<p className={styles.welcome}>
											{t('pages.home.title')}
											<span className={styles.name}>{` ${t('pages.home.name')}`}</span>
										</p>
									</div>
									<p className={styles.subtitle}>{t('pages.home.subtitle')}</p>
								</div>
								<Logo />
							</>
						)}
						{windowWidth <= config.viewSwitchWidth && (
							<div className={styles.titleContainer}>
								<div className={styles.titleWrapper}>
									<div className={styles.title}>
										<p className={styles.welcome}>
											{t('pages.home.title')}
											<span className={styles.name}>{` ${t('pages.home.name')}`}</span>
										</p>
									</div>
									<Logo />
								</div>
								<p className={styles.subtitle}>{t('pages.home.subtitle')}</p>
							</div>
						)}
					</div>
					{windowWidth > config.viewSwitchWidth && (
						<div className={styles.cards}>
							<div className={styles.left}>
								<ValuesCard />
								<div className={styles.vertical}>
									<StrategyCard />
									<FiguresCard />
								</div>
							</div>
							<div className={styles.right}>
								<FedpolCard />
								<WaypointCard />
							</div>
						</div>
					)}
					{windowWidth <= config.viewSwitchWidth && (
						<div className={styles.cards}>
							<div className={styles.left}>
								<ValuesCard />
								<FiguresCard />
							</div>
							<div className={styles.right}>
								<FedpolCard />
								<StrategyCard />
								<WaypointCard />
							</div>
						</div>
					)}
					<div className={styles.digitalContainer}>
						<div className={styles.digital}>{t('pages.home.digital.title')}</div>
						<div className={styles.socialContainer}>
							<Icon type="switzerland" />
							<a
								href={`https://www.fedpol.admin.ch/fedpol/${activeLanguage}/home.html`}
								target="_blank"
								className={styles.link}
								rel="noreferrer"
							>
								{'fedpol.admin.ch'}
							</a>
						</div>
						<div className={styles.socialContainer}>
							<Icon type="twitter" />
							<a
								href="https://twitter.com/fedpolch"
								target="_blank"
								className={styles.link}
								rel="noreferrer"
							>
								{'@fedpolCH'}
							</a>
						</div>
						<div className={styles.socialContainer}>
							<Icon type="linkedin" />
							<a
								href="https://ch.linkedin.com/company/fedpol"
								target="_blank"
								className={styles.link}
								rel="noreferrer"
							>
								{t('pages.home.digital.linkedin')}
							</a>
						</div>
						<div className={styles.socialContainer}>
							<Icon type="youtube" />
							<a
								href="https://www.youtube.com/channel/UCkvibByhHRWfmUt_J8tM1Iw"
								target="_blank"
								className={styles.link}
								rel="noreferrer"
							>
								{t('pages.home.digital.youtube')}
							</a>
						</div>
						<div className={styles.divider}></div>
					</div>
					<div className={styles.imprintContainer}>
						<a
							href={t('pages.home.imprint.link') as string}
							target="_blank"
							rel="noreferrer"
							className={styles.imprintLink}
						>
							<p className={styles.imprint}>{t('pages.home.imprint.title')}</p>
						</a>
						<div className={styles.imprintContent}>
							<Icon type="switzerland" />
							<div className={styles.imprintText}>
								<p>{t('pages.home.imprint.de')}</p>
								<p>{t('pages.home.imprint.fr')}</p>
								<p>{t('pages.home.imprint.it')}</p>
								<p>{t('pages.home.imprint.rm')}</p>
								<p className={styles.department}>{t('pages.home.imprint.department')}</p>
								<p className={styles.fedpol}>{t('pages.home.imprint.fedpol')}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default HomePage;
