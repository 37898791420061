import cx from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import werteFr from '../../assets/images/werte-fr.svg';
import werteIt from '../../assets/images/werte-it.svg';
import werteDe from '../../assets/images/werte.svg';
import { config } from '../../common/config';
import Icon from '../../components/Icon/Icon';
import useLanguage from '../../hooks/useLanguage';
import { LanguageEnum } from '../../i18n';
import { useScroll } from '../../provider/ScrollProvider/ScrollProvider';
import { useWindow } from '../../provider/WindowProvider/WindowProvider';
import pages from '../../styles/pages.module.scss';
import styles from './ValuesPage.module.scss';

const ValuesPage = (): ReactElement => {
	const { t } = useTranslation();
	const { windowWidth } = useWindow();
	const { memorizedScrollPosition } = useScroll();
	const { language } = useLanguage();

	const navigate = useNavigate();

	let translatedWerte;
	switch (language) {
		case LanguageEnum.DE:
			translatedWerte = werteDe;
			break;
		case LanguageEnum.FR:
			translatedWerte = werteFr;
			break;
		case LanguageEnum.IT:
			translatedWerte = werteIt;
			break;
		default:
			translatedWerte = werteDe;
	}

	return (
		<div className={cx(pages.wrapper, styles.wrapper)}>
			<div className={pages.container}>
				<div className={pages.closeContainer}>
					<Icon
						type="close"
						onClick={() => {
							navigate('/');
							window.scrollTo(memorizedScrollPosition.x, memorizedScrollPosition.y);
						}}
					/>
				</div>
				<div className={pages.contentWrapper}>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-left" size={48} invisible onClick={() => navigate('/figures')} />
					)}
					<div className={pages.contentContainer}>
						<div className={styles.valueContainer}>
							<img src={translatedWerte} alt="figures" className={styles.values} />
						</div>
						<div className={pages.textContainer}>
							{windowWidth > config.viewSwitchWidth && (
								<p className={pages.header}>{t('pages.values.text.header')}</p>
							)}
							<p className={pages.title}>{t('pages.values.text.title')}</p>
							<p className={pages.description}>{t('pages.values.text.description')}</p>
						</div>
					</div>
					{windowWidth > config.viewSwitchWidth && (
						<Icon type="arrow-right" size={48} onClick={() => navigate('/fedpol')} />
					)}
				</div>
			</div>
		</div>
	);
};

export default ValuesPage;
