import cx from 'classnames';
import { ReactElement } from 'react';
import anchor from '../../assets/icons/anchor.png';
import arrowLeft from '../../assets/icons/arrow-left.png';
import arrowRight from '../../assets/icons/arrow-right.png';
import close from '../../assets/icons/close.png';
import linkedin from '../../assets/icons/linkedin.png';
import plus from '../../assets/icons/plus.png';
import switzerland from '../../assets/icons/switzerland.svg';
import twitter from '../../assets/icons/x.png';
import youtube from '../../assets/icons/youtube.png';
import { IconType } from '../../types/icon-types';
import styles from './Icon.module.scss';

type Props = {
	type: IconType;
	size?: number;
	color?: 'light' | 'dark';
	border?: number;
	invisible?: boolean;
	onClick?: () => void;
};

const Icon = ({ type, size, color, border, onClick, invisible }: Props): ReactElement => {
	const source = cx(
		type === 'switzerland' && switzerland,
		type === 'twitter' && twitter,
		type === 'linkedin' && linkedin,
		type === 'youtube' && youtube,
		type === 'plus' && plus,
		type === 'close' && close,
		type === 'arrow-left' && arrowLeft,
		type === 'arrow-right' && arrowRight,
		type === 'anchor' && anchor,
	);

	return (
		<img
			src={source}
			alt={type}
			style={{
				height: `${size ?? 32}px`,
				width: `${size ?? 32}px`,
				borderRadius: `${border ?? 4}px`,
				visibility: invisible ? 'hidden' : 'visible',
			}}
			className={cx(
				onClick instanceof Function && styles.clickable,
				color === 'light' && styles.light,
				color === 'dark' && styles.dark,
			)}
			onClick={onClick}
		/>
	);
};

export default Icon;
